<template>
  <div>
    <a-drawer
      title="用户列表"
      placement="right"
      :closable="false"
      :visible="visible"
      :after-visible-change="afterVisibleChange"
      destroy-on-close
      width="600"
      @close="onClose"
    >
      <div class="header-box flex-space-between">
        <a-button type="primary" @click="modalVisible = true">添加用户</a-button>
        <div class="flex-space-between">
          <a-input class="margin-right-20" v-model="searchPhone" placeholder="请输入手机号" />
          <a-button type="primary" @click="fetchData">搜索</a-button>
        </div>
      </div>
      <a-table :columns="columns" :data-source="tableData" row-key="phone" :pagination="false" :loading="tableLoading">
        <span slot="number" slot-scope="number, record, index">
          <span>{{ index + 1 }}</span>
        </span>
        <span slot="putawayFlag" slot-scope="putawayFlag, recode">
          {{ +putawayFlag === 0 ? '未上架' : '已上架'}}
        </span>
        <span slot="operation" slot-scope=" operation, record">
          <a-popconfirm title="确认要删除当前手机号吗？" ok-text="确定" cancel-text="取消" @confirm="handleDelete(record)">
            <a class="margin_right20">删除</a>
          </a-popconfirm>
        </span>
      </a-table>
      <MyPagination :count="count" :pageNo="pageNo" @showSizeChangeFn="showSizeChangeFn" v-show="!tableLoading"/>
    </a-drawer>
    <!-- 添加用户 -->
    <a-modal
      title="添加用户"
      :visible="modalVisible"
      :footer="null"
      destroy-on-close
      :mask-closable="false"
      @cancel="onCloseModal"
    >
      <a-form-model
        ref="fromInfo"
        :model="formInfo"
        :rules="rules"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 16 }"
      >
        <a-form-model-item label="添加指定用户" ref="phones" prop="phones">
          <a-textarea v-model="formInfo.phones" :rows="15" placeholder="请输入用户手机号码，一行一个，最多添加500个"></a-textarea>
        </a-form-model-item>
        
        <a-form-model-item :wrapper-col="{ span: 16, offset: 6 }">
        <a-button type="primary" @click="handleOk('fromInfo')">
          确定
        </a-button>
        <a-button style="margin-left: 10px" @click="onCloseModal">
          取消
        </a-button>
      </a-form-model-item>
    </a-form-model>
    </a-modal>
  </div>
</template>
<script>
import { showUserPhone, delUserPhone, addUserPhone } from '@/request/api/coursManage'
import { message } from 'ant-design-vue'
import MyPagination from "@/components/pagination/MyPagination";
export default {
  components: { MyPagination },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    moduleId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      tableData: [],
      columns: [
        {
          title: '手机号',
          dataIndex: 'phone',
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' },
        }
      ],
      tableLoading: true,
      searchPhone: '',
      modalVisible: false,
      rules: {
        phone: [
          { required: true, message: '请输入用户手机号', trigger: 'change' }
        ]
      },
      formInfo: {
        phones: ''
      },
      count: 0,
      pageNo: 1,
      pageSize: 20
    }
  },
  methods: {
    afterVisibleChange(val) {
      if (val) this.fetchData()
    },
    async fetchData() {
      const params = {
        moduleId: this.moduleId,
        phone: this.searchPhone,
        pageNo: this.pageNo,
        pageSize: this.pageSize
      }
      this.tableLoading = false
      const { code, data } = await showUserPhone(params)
      this.tableLoading = false
      if (data === null) this.tableData =  []
      if ( code !== 200) return
      this.tableData = data.data
      this.count = data.count
    },
    onClose() {
      this.$emit('close')
    },
    async handleDelete(recode) {
      const { code } = await delUserPhone({ refId: recode.refId })
      if (code !== 200) return
      message.success('删除成功')
      this.fetchData()
    },
    onCloseModal() {
      this.modalVisible = false 
      this.formInfo.phones = ''
    },
    handleOk(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          const userPhones = this.formInfo.phones.split('\n')
          const params = {
            moduleId: this.moduleId,
            phones: Array.from(new Set(userPhones))
          }
          const { code } = await addUserPhone(params)
          if (code !== 200) return
          message.success('添加成功')
          this.fetchData()
          this.onCloseModal()
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 分页功能切换的回调
    showSizeChangeFn(current, pageSize) {
        this.pageNo = current;
        this.pageSize = pageSize;
        this.fetchData()
    },
  },
}
</script>
<style lang="less" scoped>
.margin-right-20 {
  margin-right: 20px;
}
.flex-space-between {
  display: flex;
  justify-content: space-between;
}
</style>