<template>
  <div>
    <div>
      <labelList />
    </div>
  </div>
  
</template>
<script>
import labelList from './labelList.vue'

export default {
  name: 'StudyPlan',
  components: { labelList },
}
</script>
<style lang="less" scoped>
.flex {
  display: flex;
}
.switch {
  margin-left: 20px;
  display: flex;
  align-items: center;
}
</style>