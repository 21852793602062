<template>
  <a-drawer
    title="添加/编辑"
    placement="right"
    :closable="false"
    :visible="visible"
    :after-visible-change="afterVisibleChange"
    destroy-on-close
    width="600"
    @close="onClose"
  >
    <a-form-model
      ref="info"
      :model="info"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item label="标签名称" ref="moduleName" prop="moduleName">
        <a-input v-model="info.moduleName" placeholder="请输入阶段名称" />
      </a-form-model-item>
      <a-form-model-item label="排序" ref="sort" prop="sort">
        <a-input v-model="info.sort" placeholder="数字越大，顺序越靠前" />
      </a-form-model-item>
      <a-form-model-item label="标签类型" ref="moduleType" prop="moduleType">
        <div v-if="!info.moduleId">
          <a-select v-model="info.moduleType" placeholder="请选择" style="width: 265px">
            <a-select-option v-for="item in labelTypeArr" :key="item" :value="courseDetailLabelType.get(item)">{{ item }}</a-select-option>
          </a-select>
          <div class="tips">确定后不可修改</div>
        </div>
        <span v-else>{{ LabelTypeMap.get(+info.moduleType) }}</span>
      </a-form-model-item>
      <a-form-model-item label="用户展示" ref="showType" prop="showType">
        <div v-if="!info.moduleId">
          <a-select v-model="info.showType" placeholder="请选择" style="width: 265px">
            <a-select-option v-for="item in userTypeArr" :key="item" :value="courseDetailUserType.get(item)">{{ item }}</a-select-option>
          </a-select>
          <div class="tips">确定后不可修改</div>
        </div>
        <span v-else>{{ showTypeMap.get(+info.showType) }}</span>
      </a-form-model-item>
      <a-form-model-item v-if="info.showType === 3" label="添加指定用户" ref="phone" prop="phone">
        <a-textarea v-model="info.phone" :rows="20" placeholder="请输入用户手机号码，一行一个，最多添加500个"></a-textarea>
      </a-form-model-item>
      <a-form-model-item label="上架设置" ref="status" prop="status">
        <a-radio-group v-model="info.status">
          <a-radio :value="0">
            暂不上架
          </a-radio>
          <a-radio :value="1">
            立即上架
          </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
        <a-button type="primary" @click="submitForm('info')">
          确定
        </a-button>
        <a-button style="margin-left: 10px" @click="onClose">
          取消
        </a-button>
      </a-form-model-item>
    </a-form-model>
  </a-drawer>
</template>
<script>
import { addCourseModule, editCourseModule, courseModuleDetail } from '@/request/api/coursManage'
import { courseDetailLabelType, courseDetailUserType, showTypeMap, LabelTypeMap } from '../../constant'
const userTypeArr = Array.from(courseDetailUserType.keys())
const validateSort = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请输入排序'));
  } else if (isNaN(+value)) {
    callback(new Error('请输入合法数字'));
  } else {
    callback()
  }
}
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    moduleId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      LabelTypeMap,
      showTypeMap,
      courseNo: this.$route.params.courseNo,
      stageNo: this.$route.params.stageNo || '',
      courseDetailUserType,
      userTypeArr,
      courseDetailLabelType,
      labelTypeArr: [],
      labelCol: { span: 6 },
      wrapperCol: { span: 16 },
      rules: {
        moduleName: [
          { required: true, message: '请输入标签名称', trigger: 'blur' },
        ],
        sort: [
          { required: true, validator: validateSort ,trigger: 'change' },
        ],
        moduleType: [
          { required: true, message: '请选择标签类型', trigger: 'change' },
        ],
        showType: [
          { required: true, message: '请选择用户类型', trigger: 'change' },
        ],
        status: [
          { required: true, message: '请选是否上架', trigger: 'change' }
        ]
      },
      info: {}
    }
  },
  created() {
    const metaTitle = this.$route.meta.title
    const _labelTypeArr = Array.from(courseDetailLabelType.keys())
    this.labelTypeArr = _labelTypeArr
    if (metaTitle === '二级标签管理') {
      this.labelTypeArr = _labelTypeArr.filter(item => item !== '分阶段')
    }
  },
  methods: {
    afterVisibleChange(val) {
      this.info = {}
      if (val && this.moduleId) this.getDetail()
    },
    async getDetail() {
      const { code, data } = await courseModuleDetail({ moduleId: this.moduleId})
      if (code !== 200) return
      this.info = Object.assign(data, { moduleId: this.moduleId })
    },
    onClose() {
      this.$emit('close')
    },
    // 提交
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          +this.moduleId ? this.edit() : this.add()
        } else {
          return false;
        }
      });
    },
    // 添加
    async add() {
      const userPhones = this.info.phone ? this.info.phone.split('\n') : []
      const params = {
        stageNo: this.stageNo,
        courseNo: this.courseNo,
        ...this.info,
        sort: +this.info.sort,
        userPhones: [...new Set(userPhones)]
      }
      delete params.phone
      const { code } = await addCourseModule(params)
      if (code !== 200) return
      this.$message.success('添加成功')
      this.onClose()
      this.$emit('fetch')
    },
    // 编辑
    async edit() {
      const params = {
        ...this.info,
        sort: +this.info.sort,
      }
      delete params.phone
      const { code } = await editCourseModule(params)
      if (code !== 200) return
      this.$message.success('修改成功')
      this.onClose()
      this.$emit('fetch')
    },
  },
}
</script>
<style lang="less" scoped>
.tips {
	color: #ccc;
}
</style>