<template>
  <div>
    <a-table :columns="columns" :data-source="tableData" :row-key="rowKey" :pagination="false" :loading="tableLoading">
      <span slot="number" slot-scope="number, record, index">
        <span>{{ index + 1 }}</span>
      </span>
      <span slot="operation" slot-scope=" operation, record">
        <a class="margin_right20" @click="handleOnPut(record)">{{ +record.status ? '下架' : '上架' }}</a>
        <a class="margin_right20" @click="$emit('handler-edit', record)">编辑</a>
        <a v-if="!noContentManageMap.includes(+record.moduleType)" class="margin_right20" @click="handleContent(record)">内容管理</a>
        <a class="margin_right20" v-if="+record.showType === 3" @click="handlerUserList(record)">用户列表</a>
      </span>
    </a-table>
    <UploadImage :is-show="showUpdateImgModal" :module-id="ruleForm.moduleId" :show-radio="ruleForm.moduleType !== 9" :title="ruleForm.modalTitle" @close="() => showUpdateImgModal = false" />
  </div>
</template>
<script>
import { noContentManageMap } from '../../constant'
import UploadImage from './Picture.vue'
import { updateCourseModuleStatus } from '@/request/api/coursManage'
import { message } from 'ant-design-vue'
export default {
  components: { UploadImage },
  props: {
    columns: {
      type: Array,
      default: () => {[]}
    },
    tableData: {
      type: Array,
      default: () => {[]}
    },
    rowKey: {
      type: String,
      default: 'moduleId'
    },
    tableLoading: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      noContentManageMap,
      showUpdateImgModal: false,
      courseNo: this.$route.params.courseNo,
      ruleForm: {},
    }
  },
  methods: {
    // 点击用户列表
    handlerUserList(recode) {
      this.$emit('handler-user', recode)
    },
    // 点击内容管理
    handleContent(recode) {
      // 1.课程洋情 2.日版目录 3.伴读资料 4.圈子 5.相关推荐 6.分阶段 7.新版目录-分章节 8.新版目录-不分章节 9.图片 10.按钮
      if (+recode.moduleType === 9 || +recode.moduleType === 10) {
        recode.modalTitle = +recode.moduleType === 9 ? '内容管理-图片' : '内容管理-按钮跳转'
        this.showUpdateImgModal = true
        this.ruleForm = recode
      } else if (+recode.moduleType === 7 || +recode.moduleType === 8) {
        // 分章节 或 不分章节 跳转页面
        const catalogue = +recode.moduleType === 8 ? 0 : 1
        const path = `/coursManage/courseList/CourseDetail/${this.courseNo}/${recode.moduleId}?catalogueFlag=${catalogue}`
        this.$router.push(path)
      } else if (+recode.moduleType === 6) {
        const path = `/coursManage/courseList/CourseDetail/stageList/${this.courseNo}/${recode.moduleId}`
        this.$router.push(path)
      }
    },
    // 上架
    async handleOnPut(recode) {
      const params = {
        moduleId: recode.moduleId,
        status: recode.status ? 0 : 1
      }
      const { code } = await updateCourseModuleStatus(params)
      if (code !== 200) return
      const text = recode.status ? '下架' : '上架'
      message.success(`${text}成功`)
      recode.status = recode.status ? 0 : 1
    },
  }
}
</script>