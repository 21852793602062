<template>
  <div>
    <div>
      <div class="btn-box flex">
        <a-button class="btn" icon="plus" type="primary" @click="handleEdit({})">新建{{$route.meta.secondRoute ? '一' : '二'}}级标签</a-button>
      </div>
      <!-- 表格 -->
      <Table :columns="columns" :table-data="tableData" :table-loading="tableLoading" @handler-edit="handleEdit" @handler-user="handlerUserList" />
      <!-- 添加/编辑 -->
      <AddLabel :visible="showAddDrawer" :module-id="ruleForm.moduleId" @close="() => showAddDrawer=false" @fetch="fetchData" />
      <UserList :visible="showUserDrawer" :module-id="ruleForm.moduleId" @close="showUserDrawer=false" />
    </div>
    <!-- <router-view /> -->
  </div>
</template>
<script>
import { courseModuleList } from '@/request/api/coursManage'
import { courseDetailColumns } from '../constant'
import AddLabel from './components/addLabel.vue'
import UserList from './components/userList.vue'
import Table from './components/Table.vue'

export default {
  name: 'StudyPlan',
  components: { AddLabel, UserList, Table },
  data() {
    return {
      courseNo: this.$route.params.courseNo,
      stageNo: this.$route.params.stageNo,
      columns: courseDetailColumns,
      tableLoading: false,
      tableData: [],
      showAddDrawer: false,
      showUserDrawer: false,
      confirmLoading: false,
      ruleForm: {}
    }
  },
  beforeRouteEnter(to, from, next) {
    console.log(to, from,)
    next()
  },
  created() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      this.tableLoading = true
      const params = {
        courseNo: this.courseNo,
        stageNo: this.stageNo
      }
      const { code, data } = await courseModuleList(params)
       this.tableLoading = false
      if (code !==200) return
      this.tableData = data
    },
    
    // 点击添加、编辑
    handleEdit(stage = {}) {
      this.ruleForm = stage
      this.showAddDrawer = true
    },
    
    // 点击用户列表
    handlerUserList(record) {
      this.ruleForm = record
      this.showUserDrawer = true
    }
  }
}
</script>
<style lang="less" scoped>
.flex {
  display: flex;
}
.switch {
  margin-left: 20px;
  display: flex;
  align-items: center;
}
</style>